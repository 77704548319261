<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 pb-1">
        <dx-util-select-box
          v-model="status"
          label="Status"
          :items="statusOptions"
          display-expr="text"
          value-expr="value"
          placeholder="Select status"
        />
      </div>
      <div class="col-12 pb-1">
        <dx-util-number-box
        v-model="discount"
        label="Discount"
        :min="0"
        :max="100"
        :show-clear-button="true"
        :show-spin-buttons="true"
        />
      </div>
      <div class="col-12 pt-2 text-right">
        <dx-util-button
          class="px-2"
          text="Cancel"
          type="danger"
          :styling-mode="'filled'"
            @click="cancel"
        />
        <dx-util-button
          class="px-2 ml-1"
          text="Update"
          type="success"
          :styling-mode="'filled'"
          @click="update"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomerPlanStatusOptions } from '@/enums/customerPlanStatus.enum.js'
import planManagementService from '@/http/requests/plan/planManagementService'

export default {
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: getCustomerPlanStatusOptions(),
      status: '',
      discount: 0,
    }
  },
  mounted() {
    this.discount = this.currentRow.discount
    this.status = this.currentRow.status
  },
  methods: {
    update() {
      if (this.status !== this.currentRow.status) {
        this.updateStatus()
      }
      if (this.discount !== this.currentRow.discount) {
        this.updateDiscount()
      }
    },
    updateStatus() {
      const params = { planId: this.currentRow.id, status: this.status }
      planManagementService.changeStatus(params).then(() => {
        this.$emit('update-closed')
      })
    },
    updateDiscount() {
      const params = { planId: this.currentRow.id, discount: this.discount }
      planManagementService.applyDiscount(params).then(() => {
        this.$emit('update-closed')
      })
    },
    cancel() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
